import React from "react";
import styled from "styled-components";
import posed from "react-pose";
import { logo } from "../../assets/svg/icons";
import InlineSVG from "svg-inline-react";

const StyledLoading = styled.div`
  z-index: 9999;
  position: absolute;
  background: black;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 60px;
  font-weight: 800;
  height: 100vh;
  span {
    color: #d2c3b4;
    margin-left: 20px;
  }
  svg {
    width: 300px;
    height: 300px;
  }
`;

const StyledAnimated = posed(StyledLoading)({
  hidden: { top: 1000, opacity: 0, transition: { duration: 500 } },
  visible: { left: 0, opacity: 1 }
});

const Loading = props => {
  return (
    <StyledAnimated pose={props.animation}>
      {/* <div>
        ICD<span>Group</span>
      </div> */}
      <div>
        <InlineSVG src={logo} />
      </div>
    </StyledAnimated>
  );
};

export default Loading;
