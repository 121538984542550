import React, { Component } from "react";
import styled from "styled-components";
import homeImage from "../../assets/images/home.jpeg";

import { home } from "../../config";

const HomeWrapper = styled.section`
  height: 100vh;
  background: url(${homeImage});
  background-size: cover;
  display: flex;
  align-items: flex-end;
  position: relative;
  z-index: -4;
  width: auto;
  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
    padding-top: 300px;
    justify-content: flex-end;
  }
  @media (max-width: 650px) {
    padding-top: 0;
  }
  .rectangle {
    position: absolute;
    width: 138px;
    height: 69px;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.9);
    @media (max-width: 1200px) {
      display: none;
    }
  }
  .company-name {
    width: 39%;
    height: 377px;
    background: #fff;
    color: rgba(0, 0, 0, 0.9);
    font-family: "Playfair Display";
    font-weight: bold;
    line-height: 79px;
    font-size: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 138px;
    @media (max-width: 1200px) {
      width: calc(100% - 276px);
      margin-left: 0;
    }
    @media (max-width: 650px) {
      min-width: calc(100% - 100px);
      line-height: 41px;
      font-size: 36px;
      max-height: 100px;
    }
    @media (max-width: 400px) {
      width: calc(100% - 40px);
    }
  }
  .content {
    width: 39%;
    height: 377px;
    background: rgba(0, 0, 0, 0.9);
    font-family: "Fira Sans";
    line-height: 43px;
    font-size: 18px;
    color: rgba(255, 255, 255, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 138px;
    @media (max-width: 1200px) {
      width: calc(100% - 276px);
      margin-right: 0;
    }
    @media (max-width: 650px) {
      line-height: 24px;
      font-size: 12px;
      width: calc(100% - 100px);
      height: auto;
    }
    @media (max-width: 400px) {
      width: calc(100% - 40px);
    }
    p {
      padding: 69px;
      /* width: 50%; */
      @media (max-width: 1500px) {
        /* width: 80%; */
        /* margin: 50px 0; */
      }
      @media (max-width: 768px) {
        padding: 35px;
      }
    }
  }
  .spacer {
    width: 22%;
    @media (max-width: 1200px) {
      width: 0%;
    }
  }
  .lang {
    position: absolute;
    color: white;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 138px;
    height: 134px;
    top: 0;
    right: 0;
    font-family: "Fira Sans";
    z-index: 101;
    cursor: pointer;
    text-transform: uppercase;
    @media (max-width: 925px) {
      display: none;
    }
  }
`;

class Home extends Component {
  render() {
    return (
      <HomeWrapper id="home">
        {/* <div className="rectangle" /> */}
        <div className="company-name">{home[this.props.language].title}</div>
        <div className="content">
          <p>{home[this.props.language].text}</p>
        </div>
        <div className="spacer" />
      </HomeWrapper>
    );
  }
}

export default Home;
