import React, { Component } from "react";
import styled from "styled-components";
import ReferencesBox from "../../components/ReferencesBox";
import { references } from "../../config";

const ReferencesWrapper = styled.section`
  height: 100vh;
  min-height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 138px;
  position: relative;
  background: #fff;

  @media (max-width: 1200px) {
    height: auto;
  }
  @media (max-width: 650px) {
    padding: 0 50px;
  }
  @media (max-width: 400px) {
    padding: 0 20px;
  }
  .references {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    @media (max-width: 1200px) {
      flex-direction: column;
    }
  }
  .background--title {
    max-width: 100vw;
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 800;
    line-height: 294px;
    font-size: 288px;
    color: rgba(0, 0, 0, 0.02);
    position: absolute;
    right: 0;
    top: 25%;
    z-index: -1;
    @media (max-width: 1700px) {
      display: none;
    }
  }
  h2 {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: bold;
    line-height: 49px;
    font-size: 48px;
    margin-bottom: 150px;
    @media (max-width: 1200px) {
      margin-bottom: 50px;
      margin-left: 50px;
    }
    @media (max-width: 650px) {
      line-height: 35px;
      font-size: 30px;
      margin-left: 25px;
      /* margin-bottom: 50px; */
    }
  }
`;

class References extends Component {
  render() {
    return (
      <ReferencesWrapper id="references">
        <h2>{references[this.props.language].title}</h2>
        <div className="references">
          {references[this.props.language].references.map((r, i) => {
            return (
              <ReferencesBox
                title={r.title}
                items={r.items}
                svg={r.svg || null}
                image={r.image || null}
                key={i}
              />
            );
          })}
        </div>
        <div className="background--title">
          {references[this.props.language].title}
        </div>
      </ReferencesWrapper>
    );
  }
}

export default References;
