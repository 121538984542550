import React, { Component } from "react";
import styled from "styled-components";
import mobileImage from "../../assets/images/mobile-phone.png";
import InlineSVG from "svg-inline-react";
import { projects } from "../../config";
import posed from "react-pose";
import { arrowLeft, arrowRight } from "../../assets/svg/icons";

const Box = posed.div({
  hidden: { opacity: 0, transition: { duration: 300 } },
  visible: { opacity: 1, transition: { duration: 300 } }
});

const ProjectsWrapper = styled.section`
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  background: #fff;
  z-index: 1;
  /* @media (max-height: 1024px) {
    height: auto;
  } */
  @media (max-width: 1400px) {
    height: auto;
  }
  @media (max-width: 1200px) {
    flex-direction: column;
    margin-bottom: 100px;
  }
  @media (max-width: 925px) {
    background: #fff;
  }
  .rectangle {
    position: absolute;
    width: 100%;
    height: 248px;
    bottom: 0;
    background: #d2c3b4;
    z-index: -4;
    /* @media (max-width: 1200px) {
      display: none;
    } */
  }
  .rectangle--light {
    position: absolute;
    width: 138px;
    height: 248px;
    bottom: 0;
    right: 0;
    background: #d2c3b4;
    z-index: 4;
    @media (max-width: 1200px) {
      display: none;
    }
  }
  .image {
    /* flex: 1; */
    background: url(${mobileImage});
    background-size: cover;
    background-position: center;
    /* max-width: 633px; */
    height: 800px;
    /* padding-left: 138px; */
    width: calc(39% + 50px);
    position: relative;
    @media (max-width: 1200px) {
      flex-direction: column;
      width: 100%;
      height: 300px;
      max-height: 300px;
    }
    #mobile {
      display: none;
      height: 150px;
      @media (max-width: 1200px) {
        width: calc(100% - 276px);
        left: 138px;
        display: block;
      }
      @media (max-width: 650px) {
        width: calc(100% - 100px);
        left: 50px;
        display: block;
      }
      @media (max-width: 400px) {
        width: calc(100% - 40px);
        left: 20px;
        display: block;
      }
    }
    .image--content svg {
      position: absolute;
      top: 35.6%;
      left: 45.6%;
      @media (max-width: 650px) {
        width: 48px;
        height: 48px;
      }
      @media (max-width: 400px) {
        left: 44.6%;
      }
    }
    .image--content img {
      position: absolute;
      top: 35.6%;
      left: 45.6%;
      width: 76px;
      height: 76px;
      @media (max-width: 650px) {
        width: 48px;
        height: 48px;
      }
      @media (max-width: 400px) {
        left: 44.6%;
      }
    }
    .prev {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 70px;
      height: 70px;
      background: #000;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      z-index: 1;
      @media (max-width: 1200px) {
        bottom: -220px;
        right: 50%;
      }
      @media (max-width: 650px) {
        width: 50px;
        height: 50px;
        right: 50px;
        bottom: 0;
        left: auto;
      }
    }
  }
  .content {
    /* margin-left: 15%; */
    /* flex: 1; */
    width: 61%;
    background: #fff;
    position: relative;
    @media (max-width: 1200px) {
      flex-direction: column;
      width: 100%;
    }
    .next {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 70px;
      height: 70px;
      background: #000;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @media (max-width: 1200px) {
        top: 150px;
        left: 50%;
      }
      @media (max-width: 650px) {
        width: 50px;
        height: 50px;
        bottom: auto;
        right: 0;
        left: auto;
        top: -50px;
      }
    }
    h2 {
      font-family: "Playfair Display";
      font-style: normal;
      font-weight: bold;
      line-height: 54px;
      font-size: 48px;
      color: #000;
    }
    h2.main--title {
      margin-left: 276px;
      margin-bottom: 172px;
      @media (max-width: 1200px) {
        /* margin: 0 calc(138px + 69px); */
        margin-left: 0;
        text-align: center;
      }
      @media (max-width: 1024px) {
        margin-left: 138px;
        margin-right: 0;
        width: calc(100% - 276px);
      }
      @media (max-width: 650px) {
        line-height: 35px;
        font-size: 30px;
        width: calc(100% - 100px);
        margin-left: 80px;
        margin-bottom: 50px;
        text-align: left;
      }
      @media (max-width: 400px) {
        width: calc(100% - 100px);
        margin-left: 50px;
        margin-bottom: 10px;
      }
    }
    .slide {
      height: 450px;
      margin-left: 276px;
      @media (max-width: 1200px) {
        height: auto;
      }
      @media (max-width: 650px) {
        margin-left: 80px;
      }
      @media (max-width: 400px) {
        margin-left: 50px;
      }
      .title {
        display: flex;
        flex-direction: row;
      }
      h2.title--number {
        margin-left: -100px;
        margin-right: 50px;
        @media (max-width: 650px) {
          line-height: 35px;
          font-size: 30px;
          margin-left: 0;
          margin-right: 20px;
        }
      }
      h2.title--text {
        color: #d2c3b4;
        @media (max-width: 650px) {
          line-height: 35px;
          font-size: 30px;
        }
      }
      p {
        width: 50%;
        font-family: "Fira Sans";
        font-style: normal;
        font-weight: normal;
        line-height: 36px;
        font-size: 15px;
        color: rgba(0, 0, 0, 0.6);
        @media (max-width: 1200px) {
          width: calc(100% - 138px);
        }
        @media (max-width: 650px) {
          line-height: 24px;
          font-size: 12px;
        }
        @media (max-width: 400px) {
          width: calc(100% - 40px);
        }
      }
    }
  }
  .background--title {
    max-width: 100vw;
    position: absolute;
    left: -290px;
    bottom: 200px;
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 800;
    line-height: 294px;
    font-size: 288px;
    color: rgba(0, 0, 0, 0.02);
    z-index: -2;
    @media (max-width: 1200px) {
      display: none;
    }
  }
`;

class Projects extends Component {
  state = {
    current: 0,
    isVisible: true,
    moving: false
  };

  slider(direction) {
    if (this.state.moving === false) {
      this.setState({ moving: true });
      let x = 0;
      let y = setInterval(() => {
        this.setState({ isVisible: !this.state.isVisible });
        if (++x === 2) {
          this.setState(prevState => ({
            current:
              direction === "prev"
                ? prevState.current !== 0
                  ? prevState.current - 1
                  : projects[this.props.language].projects.length - 1
                : prevState.current !==
                  projects[this.props.language].projects.length - 1
                ? prevState.current + 1
                : 0,
            moving: false
          }));
          window.clearInterval(y);
        }
      }, 300);
    }
  }

  render() {
    return (
      <ProjectsWrapper id="projects">
        <div className="rectangle" />
        <div className="image">
          <div className="rectangle" id="mobile" />
          <Box
            className="image--content"
            pose={this.state.isVisible ? "visible" : "hidden"}
          >
            {projects[this.props.language].projects[this.state.current].svg ? (
              <InlineSVG
                src={
                  projects[this.props.language].projects[this.state.current].svg
                }
              />
            ) : (
              <img
                src={
                  projects[this.props.language].projects[this.state.current]
                    .image
                }
                alt="Project"
              />
            )}
          </Box>
          <div onClick={() => this.slider("prev")} className="prev">
            <InlineSVG src={arrowRight} />
          </div>
        </div>
        <div className="content">
          <div onClick={() => this.slider("next")} className="next">
            <InlineSVG src={arrowLeft} />
          </div>
          <h2 className="main--title">
            {projects[this.props.language].mainTitle}
          </h2>
          <Box pose={this.state.isVisible ? "visible" : "hidden"}>
            <div className="slide">
              <div className="title">
                <h2 className="title--number">0{this.state.current + 1}</h2>
                <h2 className="title--text">
                  {
                    projects[this.props.language].projects[this.state.current]
                      .title
                  }
                </h2>
              </div>
              <p>
                {
                  projects[this.props.language].projects[this.state.current]
                    .text
                }
              </p>
            </div>
          </Box>
        </div>
        <div className="background--title">
          {projects[this.props.language].backgroundTitle}
        </div>
        <div className="rectangle--light" />
      </ProjectsWrapper>
    );
  }
}

export default Projects;
