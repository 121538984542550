import React, { Component } from "react";
import styled from "styled-components";
import servicesImage from "../../assets/images/services.jpeg";
import CheckItem from "../../components/CheckItem";

import { services } from "../../config";

const ServicesWrapper = styled.section`
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  background: #fff;
  @media (max-height: 1024px) {
    height: auto;
  }
  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
  }
  .rectangle {
    position: absolute;
    width: 138px;
    height: 138px;
    left: 0px;
    top: 134px;
    background: rgba(0, 0, 0, 0.9);
    z-index: 2;
    @media (max-width: 1200px) {
      display: none;
    }
  }
  .image {
    background: url(${servicesImage});
    background-size: cover;
    padding-left: 138px;
    height: calc(100vh - 134px);
    width: 39%;
    @media (max-width: 1200px) {
      padding-left: 0;
      width: calc(100% - 276px);
      height: 300px;
    }
    @media (max-width: 650px) {
      width: calc(100% - 100px);
      height: 200px;
      margin: 0;
    }
    @media (max-width: 400px) {
      width: calc(100% - 40px);
    }
  }
  .content {
    height: calc(100vh - 134px);
    background: #000;
    width: 61%;
    color: #d2c3b4;
    font-family: "Playfair Display";
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 69px;
    @media (max-width: 1200px) {
      width: auto;
      margin: 0 138px;
      height: auto;
      padding-top: 30px;
    }
    @media (max-width: 768px) {
      padding: 0 30px;
    }
    @media (max-width: 650px) {
      margin: 0 50px;
      padding: 50px 30px;
    }
    @media (max-width: 400px) {
      margin: 0 20px;
    }
    h2 {
      font-style: normal;
      font-weight: bold;
      line-height: 54px;
      font-size: 48px;
      margin: 0;
      @media (max-width: 650px) {
        line-height: 35px;
        font-size: 30px;
      }
    }
    p.subtitle {
      font-size: 24px;
      width: 65%;
      color: rgba(255, 255, 255, 0.8);
      @media (max-width: 1600px) {
        font-size: 20px;
        line-height: 33px;
      }
      @media (max-height: 768px) {
        width: 100%;
      }
      @media (max-width: 650px) {
        line-height: 25px;
        font-size: 16px;
      }
      @media (max-width: 400px) {
        width: 100%;
      }
    }
    div > p {
      margin: 0;
    }
    .items {
      @media (max-width: 1200px) {
        margin-bottom: 50px;
      }
      @media (max-width: 768px) {
        margin: 50px 0;
      }
    }
  }
  .rectangle--light {
    position: absolute;
    width: 138px;
    height: 138px;
    right: 0;
    bottom: 0;
    background: #d2c3b4;
    z-index: 2;
    @media (max-width: 1200px) {
      display: none;
    }
  }
`;

class Services extends Component {
  render() {
    return (
      <ServicesWrapper id="services">
        <div className="rectangle" />
        <div className="image" />
        <div className="content">
          <h2>{services[this.props.language].title}</h2>
          <p className="subtitle">{services[this.props.language].text}</p>
          <div className="items">
            {services[this.props.language].checkList.map((item, key) => {
              return <CheckItem key={key}>{item}</CheckItem>;
            })}
          </div>
        </div>
        <div className="rectangle--light" />
      </ServicesWrapper>
    );
  }
}

export default Services;
