import React, { Component } from "react";
import Landing from "./pages/Landing";
import Loading from "./pages/Loading";
import Header from "./sections/_Header";
import Footer from "./sections/_Footer";
import { animations } from "./config";
import Burger from "./components/Burger";
import "./scss/main.scss";

class App extends Component {
  constructor(props) {
    super(props);
    if (animations.status) {
      this.state = {
        show: true,
        loader: "visible",
        header: "hidden",
        active: null,
        language: "eng",
        burger: false
      };
    } else {
      this.state = {
        show: false,
        loader: "hidden",
        header: "visible",
        active: null,
        language: "eng",
        burger: false
      };
    }
  }

  toggleLanguage() {
    this.setState(prevState => ({
      language: prevState.language === "eng" ? "srb" : "eng"
    }));
  }

  toggleBurger() {
    this.setState(prevState => ({ burger: !prevState.burger }));
  }

  componentDidMount() {
    if (animations.status) {
      setInterval(() => {
        this.setState({ loader: "hidden" });
      }, animations.loader);
      setInterval(() => {
        this.setState({ show: false });
      }, animations.scrollbar);
      setInterval(() => {
        this.setState({ header: "visible" });
      }, animations.header);
    } else {
    }
  }

  render() {
    return (
      <>
        <Burger
          toggleLanguage={() => this.toggleLanguage()}
          language={this.state.language}
          burger={this.state.burger}
          toggleBurger={() => this.toggleBurger()}
        />
        <div className="lang" onClick={() => this.toggleLanguage()}>
          {this.state.language === "eng" ? "srb" : "eng"}
        </div>
        {this.state.show ? <Loading animation={this.state.loader} /> : null}
        {this.state.show ? null : (
          <Header
            animation={this.state.header}
            language={this.state.language}
            burger={this.state.burger}
          />
        )}
        <Landing
          toggleLanguage={() => this.toggleLanguage()}
          language={this.state.language}
        />
        <Footer language={this.state.language} />
      </>
    );
  }
}

export default App;
