import React, { Component } from "react";
import { Link } from "react-scroll";
import { Slider } from "react-burgers";
import styled from "styled-components";
import { header } from "../../config";
import { logoBackground } from "../../assets/svg/icons";
import InlineSVG from "svg-inline-react";

const BurgerWrapper = styled.div`
  display: none;
  position: fixed;
  z-index: 100;
  right: 0;
  top: 0;
  mix-blend-mode: exclusion;
  @media (max-width: 925px) {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 134px;
    margin-right: 138px;
  }
  @media (max-width: 650px) {
    margin-right: 50px;
  }
  @media (max-width: 400px) {
    margin-right: 20px;
  }
  .BurgerInner,
  .BurgerInner:after,
  .BurgerInner:before {
    z-index: 11;
    background: #fff;
    height: 3px;
    color: white;
  }
  .BurgerInner:before {
    width: 50%;
    margin-left: 50%;
  }
  .BurgerInner:after {
    display: ${props => (props.burger ? "block" : "none")};
    width: 98%;
  }
  .BurgerBox {
    margin-right: -14px;
    margin-bottom: 40px;
  }
`;

class Burger extends Component {
  render() {
    return (
      <>
        <BurgerWrapper burger={this.props.burger}>
          <nav className="burger">
            <Slider
              active={this.props.burger}
              onClick={() => this.props.toggleBurger()}
              width={22}
            />
          </nav>{" "}
        </BurgerWrapper>

        <div
          className="burger--menu"
          style={this.props.burger ? { display: "flex" } : { display: "none" }}
        >
          <InlineSVG src={logoBackground} />
          <Link
            activeClass="active"
            to="home"
            spy={true}
            smooth={true}
            duration={500}
            onClick={() => this.props.toggleBurger()}
          >
            {header[this.props.language][0]}
          </Link>
          <Link
            activeClass="active"
            to="about"
            spy={true}
            smooth={true}
            duration={500}
            onClick={() => this.props.toggleBurger()}
          >
            {header[this.props.language][1]}
          </Link>
          <Link
            activeClass="active"
            to="services"
            spy={true}
            smooth={true}
            duration={500}
            onClick={() => this.props.toggleBurger()}
          >
            {header[this.props.language][2]}
          </Link>
          <Link
            activeClass="active"
            to="projects"
            spy={true}
            smooth={true}
            duration={500}
            onClick={() => this.props.toggleBurger()}
          >
            {header[this.props.language][3]}
          </Link>
          <Link
            activeClass="active"
            to="references"
            spy={true}
            smooth={true}
            duration={500}
            onClick={() => this.props.toggleBurger()}
          >
            {header[this.props.language][4]}
          </Link>
          <div
            className="lang--burger"
            onClick={() => this.props.toggleLanguage()}
          >
            {this.props.language === "eng" ? "srb" : "eng"}
          </div>
        </div>
      </>
    );
  }
}

export default Burger;
