import React from "react";
import InlineSVG from "svg-inline-react";
import { check } from "../../assets/svg/icons";
import styled from "styled-components";

const CheckItemWrapper = styled.p`
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: normal;
  line-height: 43px;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.8);
  @media (max-width: 1600px) {
    font-size: 15px;
    line-height: 33px;
  }
  @media (max-width: 650px) {
    line-height: 24px;
    font-size: 12px;
  }
  svg {
    padding: 0 32px;
    @media (max-width: 768px) {
      padding: 0 10px 0 0;
      width: 15px;
      height: 15px;
    }
  }
`;

const CheckItem = props => {
  return (
    <CheckItemWrapper>
      <InlineSVG src={check} />
      <span>{props.children}</span>
    </CheckItemWrapper>
  );
};

export default CheckItem;
