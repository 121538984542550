import React, { Component } from "react";
import { Element } from "react-scroll";

import Home from "../../sections/Home";
import About from "../../sections/About";
import Services from "../../sections/Services";
import Projects from "../../sections/Projects";
// import Contact from "../../sections/Contact";
// import Footer from "../../sections/_Footer";
import References from "../../sections/References";

const Lines = () => {
  return (
    <>
      <div className="line" id="line1" />
      <div className="line" id="line2" />
      <div className="line" id="line3" />
    </>
  );
};

class Landing extends Component {
  render() {
    return (
      <main>
        <Lines />
        <Element name="home">
          <Home language={this.props.language} />
        </Element>
        <Element name="about">
          <About language={this.props.language} />
        </Element>
        <Element name="services">
          <Services language={this.props.language} />
        </Element>
        <Element name="projects">
          <Projects language={this.props.language} />
        </Element>
        <Element name="references">
          <References language={this.props.language} />
        </Element>
      </main>
    );
  }
}

export default Landing;
