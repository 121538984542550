import React, { Component } from "react";
import styled from "styled-components";
import InlineSVG from "svg-inline-react";
import { Link } from "react-scroll";

import { logo } from "../../assets/svg/icons";

import posed from "react-pose";
import { header } from "../../config";

const HeaderWrapper = styled.header`
  background: transparent;
  z-index: 100;
  position: fixed;
  display: flex;
  flex-direction: row;
  font-family: "Fira Sans", sans-serif;
  width: 100%;
  @media (max-width: 650px) {
    position: ${({ burger }) => (burger ? "fixed" : "relative")};
    width: auto;
  }
  .logo--container {
    width: 39%;
    max-width: 39%;
    position: relative;
    margin-left: 138px;
    @media (max-width: 650px) {
      margin-left: 50px;
    }
    @media (max-width: 400px) {
      margin-left: 20px;
    }
    .logo {
      position: absolute;
      width: 77px;
      height: 134px;
      background: rgba(0, 0, 0, 0.9);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
  nav ul {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  nav.links {
    width: 61%;
    height: 134px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: #fff;
    margin-right: 138px;
    @media (max-width: 925px) {
      display: none;
    }
    a {
      text-decoration: none;
      line-height: 14px;
      font-size: 14px;
      color: #000;
      cursor: pointer;
      padding: 25px;
    }
    a.active {
      color: #d2c3b4;
      font-weight: bold;
      position: relative;
      transition: 0.5s;
    }
    a.active:before {
      content: "";
      position: absolute;
      left: 10%;
      bottom: 96px;
      width: 80%;
      border-bottom: 3px solid #d2c3b4;
    }
  }
`;

const HeaderAnimated = posed(HeaderWrapper)({
  hidden: { top: -200, opacity: 0, transition: { duration: 500 } },
  visible: { top: 0, opacity: 1 }
});

class Header extends Component {
  render() {
    return (
      <HeaderAnimated pose={this.props.animation} burger={this.props.burger}>
        <div className="logo--container">
          <div className="logo">
            <Link
              activeClass="active"
              to="home"
              spy={true}
              smooth={true}
              duration={500}
            >
              <InlineSVG src={logo} />
            </Link>
          </div>
        </div>
        <nav className="links">
          <Link
            activeClass="active"
            to="home"
            spy={true}
            smooth={true}
            duration={500}
          >
            {header[this.props.language][0]}
          </Link>
          <Link
            activeClass="active"
            to="about"
            spy={true}
            smooth={true}
            duration={500}
          >
            {header[this.props.language][1]}
          </Link>
          <Link
            activeClass="active"
            to="services"
            spy={true}
            smooth={true}
            duration={500}
          >
            {header[this.props.language][2]}
          </Link>
          <Link
            activeClass="active"
            to="projects"
            spy={true}
            smooth={true}
            duration={500}
          >
            {header[this.props.language][3]}
          </Link>
          <Link
            activeClass="active"
            to="references"
            spy={true}
            smooth={true}
            duration={500}
          >
            {header[this.props.language][4]}
          </Link>
        </nav>
      </HeaderAnimated>
    );
  }
}

export default Header;
