import React, { Component } from "react";
import styled from "styled-components";
// import posed from "react-pose";

import { about } from "../../config";

// const Box = posed.div({
//   hidden: { x: -110, opacity: 0, transition: { duration: 5000 } },
//   visible: { x: 0, opacity: 1, transition: { duration: 5000 } }
// });

const AboutWrapper = styled.section`
  height: 100vh;
  min-height: 800px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #fff;
  z-index: -4;
  @media (max-width: 1200px) {
    flex-direction: column;
    height: auto;
  }
  .about {
    width: 39%;
    margin-left: 138px;
    height: 438px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 1200px) {
      margin-left: 0;
      width: auto;
      height: auto;
      margin: 0 138px;
      padding: 99px 69px;
    }
    @media (max-width: 768px) {
      padding: 155px 35px;
    }
    @media (max-width: 650px) {
      margin: 0 50px;
    }
    @media (max-width: 400px) {
      margin: 0 20px;
    }
    h2 {
      font-family: "Playfair Display";
      font-style: normal;
      font-weight: bold;
      line-height: 54px;
      font-size: 48px;
      width: 58%;
      @media (max-width: 1700px) {
        margin: 0;
      }
      @media (max-width: 1200px) {
        width: 100%;
        margin: auto;
      }
      @media (max-width: 650px) {
        line-height: 35px;
        font-size: 30px;
      }
    }
    p {
      font-family: "Fira Sans";
      font-style: normal;
      font-weight: normal;
      line-height: 36px;
      font-size: 15px;
      color: rgba(0, 0, 0, 0.6);
      width: 58%;
      @media (max-width: 1200px) {
        width: 100%;
      }
      @media (max-width: 650px) {
        line-height: 24px;
        font-size: 12px;
        height: auto;
      }
    }
    .background--title {
      position: absolute;
      font-weight: 800;
      line-height: 294px;
      font-size: 288px;
      color: rgba(0, 0, 0, 0.02);
      z-index: 1;
      bottom: 0;
      font-family: "Fira Sans";
      @media (max-width: 1200px) {
        line-height: 194px;
        font-size: 188px;
      }
      @media (max-width: 650px) {
        display: none;
      }
    }
  }
  .content {
    height: 238px;
    width: 61%;
    background: #d2c3b4;
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: normal;
    line-height: 36px;
    font-size: 15px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    /* margin-right: 138px; */
    padding: 69px;
    z-index: 2;
    @media (max-width: 1700px) {
      height: auto;
    }
    @media (max-width: 1200px) {
      flex-direction: column;
      width: auto;
      margin: 0 138px;
    }
    @media (max-width: 768px) {
      padding: 35px;
    }
    @media (max-width: 650px) {
      margin: 0 50px;
      line-height: 24px;
      font-size: 12px;
    }
    @media (max-width: 400px) {
      margin: 0 20px;
    }
    p {
    }
    p:last-child {
      padding-left: 100px;
      @media (max-width: 1200px) {
        padding-left: 0;
      }
    }
  }
`;

class About extends Component {
  // state = { isVisible: true };

  // componentDidMount() {
  //   setInterval(() => {
  //     this.setState({ isVisible: !this.state.isVisible });
  //   }, 1000);
  // }

  render() {
    return (
      <AboutWrapper id="about">
        <div className="about">
          {/* <Box
            className="box"
            pose={this.state.isVisible ? "visible" : "hidden"}
            style={{ background: "red", width: "100px", height: "100px" }}
          /> */}
          <h2>{about[this.props.language].title}</h2>
          <p>{about[this.props.language].text}</p>
          <div className="background--title">
            {about[this.props.language].backgroundTitle}
          </div>
        </div>
        <div className="content">
          <p>{about[this.props.language].p1}</p>
          <p>{about[this.props.language].p2}</p>
        </div>
      </AboutWrapper>
    );
  }
}

export default About;
