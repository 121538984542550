import React, { Component } from "react";
import styled from "styled-components";

import { footer } from "../../config";

const FooterWrapper = styled.footer`
  font-family: "Fira Sans";
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .top--content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #d2c3b4;
    height: 40px;
    color: rgba(0, 0, 0, 0.6);
    @media (max-width: 650px) {
      font-size: 12px;
    }
    span:first-child {
      @media (max-width: 650px) {
        margin-left: 50px;
      }
      @media (max-width: 400px) {
        margin-left: 20px;
      }
    }
    span:last-child {
      margin-left: 60px;
      @media (max-width: 650px) {
        margin-right: 50px;
      }
      @media (max-width: 400px) {
        margin-right: 20px;
      }
    }
  }
  .content {
    width: 100%;
    height: 192px;
    background: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 650px) {
      height: 100px;
    }
    div {
      color: #fff;
      line-height: 36px;
      font-size: 15px;
      text-align: center;
    }
    p {
      margin: 0;
    }
  }
`;

class Footer extends Component {
  render() {
    return (
      <FooterWrapper>
        <div className="top--content">
          <span>{footer[this.props.language].address}</span>
          <span>{footer[this.props.language].phone}</span>
        </div>
        <div className="content">
          <div>
            <p>{footer[this.props.language].p1}</p>
            <p>{footer[this.props.language].p2}</p>
          </div>
        </div>
      </FooterWrapper>
    );
  }
}

export default Footer;
