import React from "react";
import styled from "styled-components";
import InlineSVG from "svg-inline-react";

const ReferencesBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  margin: 0;
  margin-right: 60px;
  /* @media (max-width: 650px) {
    justify-content: center;
    align-items: center;
    margin-right: 0;
  } */
  @media (max-width: 1200px) {
    margin-left: 50px;
  }
  @media (max-width: 650px) {
    margin-left: 30px;
  }
  :last-child {
    margin-right: 0;
  }
  ul {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    @media (max-width: 1200px) {
      margin-bottom: 50px;
    }
  }
  li {
    font-family: "Fira Sans";
    padding-left: 1em;
    text-indent: -1em;
    line-height: 36px;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.6);
    width: 75%;
    @media (max-width: 1200px) {
      width: 100%;
    }
    @media (max-width: 650px) {
      font-size: 12px;
      line-height: 24px;
    }
  }
  li:before {
    content: "-";
    padding-right: 5px;
  }
  .image {
    width: 114px;
    height: 114px;
    background: #ffffff;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.09);
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 60px;
    }
  }
  h3 {
    font-family: "Playfair Display";
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    font-size: 30px;
    color: #000;
    @media (max-width: 650px) {
      line-height: 30px;
      font-size: 25px;
    }
  }
  :nth-child(1) {
    svg {
      fill: black;
    }
  }
  :nth-child(2) {
    img {
      mix-blend-mode: darken;
    }
  }
  :nth-child(3) {
    img {
      mix-blend-mode: multiply;
    }
  }
`;

const ReferencesBox = props => {
  return (
    <ReferencesBoxWrapper>
      <div className="image">
        {props.svg ? (
          <InlineSVG src={props.svg} />
        ) : (
          <img src={props.image} alt="Project" />
        )}
      </div>
      <h3>{props.title}</h3>
      <ul>
        {props.items.map((item, i) => {
          return <li key={i}>{item}</li>;
        })}
      </ul>
    </ReferencesBoxWrapper>
  );
};

export default ReferencesBox;
