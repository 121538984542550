import {
  refICD,
  projectTG,
  projectHoB,
  projectAwera,
  projectPersona
} from "./assets/svg/icons";
import nitesImg from "./assets/images/ref/nites.png";
import bgInvestImg from "./assets/images/ref/bg-invest.png";
import incassoImg from "./assets/images/ref/incasso.png";
import projectZUP from "./assets/images/projects/zup.png";
import projectSmartNodes from "./assets/images/projects/smartnodes.png";

export const animations = {
  status: false,
  loader: 2000,
  scrollbar: 2800,
  header: 3000
};
export const header = {
  eng: [`Home`, `About Us`, `Services`, `Projects`, `References`],
  srb: [`Početna`, `O nama`, `Usluge`, `Projekti`, `Reference`]
};

export const home = {
  eng: {
    title: `ICD Group`,
    text: `Our team consists of successful young and ambitious people who, with
    their valuable dedication, achieve all their goals on a common path
    through various exciting projects and challenges.`
  },
  srb: {
    title: `ICD Group`,
    text: `Naš tim sastavljen je od uspešnih mladih i ambicioznih ljudi koji svojim 
    vrednim zalaganjem ostvaruju sve ciljeve na zajedničkom putu kroz različite uzbuljive projkte i izazove.`
  }
};

export const about = {
  eng: {
    title: `About us`,
    backgroundTitle: `About`,
    text: `ICD Group was established in 2017 in Belgrade. So far, it has a
    number of successfully completed projects in the field of design and
    development of information systems, the development of conceptual
    solutions in the field of IoT, as well as detailed analysis of the
    company's search and business operations.`,
    p1: `Everything that a client needs to do is present the problem he or
    she seeks to express, what kind of change he / she wants in his /
    her company and on that basis, we will present a number of proposals
    on how we can proceed. Cooperation with us represents a continuous
    process of upgrading everyday business.`,
    p2: `Our approach to challenges is based on a detailed analysis of the
    issues as well as the business processes of the client itself. On
    this basis, we are able to offer Tailor-made solutions that not only
    enable smooth operation, but also give clients the opportunity to
    improve, introducing enviable efficiency and saving both in
    resources and in financial terms.`
  },
  srb: {
    title: `O nama`,
    backgroundTitle: `O nama`,
    text: `Kompanija ICD Group osnovana je 2017. godine u Beogradu. 
    Iza sebe do sada ima niz uspešno završenih projekata na polju projektovanja i 
    izrade informacionih sistema, izrada konceptnih rešenja na polju IoT kao 
    i detaljnih analiza tražišta i poslovanja kompanija.`,
    p1: `Sve što klijent treba da uradi jeste da iznese problem sa kojim se suočava 
    ili izrazi želju kakav vid promena želi u svojoj kompaniji i na osnovu toga od nas 
    će dobiti niz predloga kako možemo nastaviti realizaciju. Saradnja sa nama predstavlja 
    kontinuirani proces usavršavanja svakodnevnog poslovanja.
    `,
    p2: `Naš pristup izazovima zasnovan je na detaljnoj analizi problematike 
    kao i samih poslovnih procesa klijneta. Na osnovu toga, u mogućnosti smo da
    ponudimo Tailor-made rešenja koja ne samo da omogućavaju nesmetan rad već i 
    klijentima daju mogućnost za dalje unapređenje, uvođenje zavidne efikasnosti
    pa i ušteda kako u resursima tako i u finansijskom smislu.`
  }
};

export const services = {
  eng: {
    title: `Our Services`,
    text: `We are very glad to accept the challenges of both large 
    corporations and small companies that need support and healthy 
    advice on how to "survive" in current market conditions and circumstances.`,
    checkList: [
      `Making customized solutions for individual users`,
      `Development of software tools and information systems`,
      `Analysis of the problem of current communication, savings and optimization`,
      `Finding problematic points of business and submitting proposals for their solution`,
      `Analysis of markets and trends`,
      `Creating web and mobile applications`,
      `Creating a Virtual Reality Response`
    ]
  },
  srb: {
    title: `Naše Usluge`,
    text: `Veoma rado prihvatamo izazove kako velikih korporacija tako 
    i malih kompanija kojima je potrebna podrška i zdrav savet 
    kako da “prežive” u aktuelnim tržnišnim uslovima i okolnostima.`,
    checkList: [
      `Izrada prilagođenih rešenja pojedinačnim korisnicima`,
      `Izrada softverskih alata i informacionih sistema`,
      `Analiza problema tekućeg poslovanja, ušteda i optimizacija`,
      `Pronalaženje problematičnih tačaka poslovanja i davanje predloga za njihovo rešavanje`,
      `Analiza tržišta i trendova`,
      `Kreiranje web i mobilnih aplikacija`,
      `Izrada rešenja Virtuelne realnosti`
    ]
  }
};

export const projects = {
  eng: {
    mainTitle: `Our Projects and Solutions`,
    backgroundTitle: `Projects`,
    projects: [
      {
        title: `House of Benefits`,
        svg: projectHoB,
        text: `Web and mobile platform is designed for providing benefits and services to the 
        employees of the company they work for, as well as for providers of such content. 
        In a simple, efficient and economical way let your employees see how much you actually 
        invest in them offering them a wide range of products and services from various fields.`
      },
      {
        title: `Persona +`,
        svg: projectPersona,
        text: `Our first VR application. Live the prospects of a parallel life you imagine every day, 
        enjoy the full VR environment with the accompanying mobile and headset application.`
      },
      {
        title: `Tour guide`,
        svg: projectTG,
        text: `As Uber for travelers, the Tour Guide mobile application makes it 
        possible to connect tourists and locals around the world. Meet hidden secrets 
        of every corner of the earth using our application, choose a guide and a tour of 
        your own size and enjoy things that only local people know. If you are a local, 
        you have the opportunity to earn extra by showing tourists some of the things you 
        know best in your area, in your city. Quick, efficient and easy!`
      },
      {
        title: `Zup! App`,
        image: projectZUP,
        text: `Express yourself easily and creatively through the image. Upload a image, 
        share it with the world, or just with your friends. Set the challenge, answer the challenge. Reply image to image!`
      },
      {
        title: `Smart Nodes`,
        image: projectSmartNodes,
        text: `The "IoT" solution offers a range of new tracking capabilities and a deep analytics of sales items. 
        By matching the micro and macro location of sales of individual items with time and social events, 
        we are able to carry out a detailed analysis of sales and interest of visitors and retail sales network customers.`
      },
      {
        title: `Awera`,
        svg: projectAwera,
        text: `The project, which in a creative and modern way enables the monitoring of visitors' interest 
        in products and services at certain points of sale, at the international level.`
      }
    ]
  },
  srb: {
    mainTitle: `Naša rešenja i projekti`,
    backgroundTitle: `Projekti`,
    projects: [
      {
        title: `House of Benefits`,
        svg: projectHoB,
        text: `Web i mobilna platforma dizajnirana da nudi benefite i servise zaposlenima u kompaniji za koju rade, kao i za provajdere ovakvog sadržaja.
        Na jednostavan, efikasan i ekonomičan način pokažite vašim zaposlenima koliko zapravo investirate u njih, pružajući im širok spektar
        proizvoda i servisa iz raznih oblasti.`
      },
      {
        title: `Persona +`,
        svg: projectTG,
        text: `Naša prva VR aplikacija. Proživi paralelan život kakav zamišljaš svakog dana, uživaj u potpunom VR 
        okruženju uz prateću mobilnu i headset aplikaciju.`
      },
      {
        title: `Tour guide`,
        svg: projectTG,
        text: `Kao Uber za putnike, tako i Tour Guide mobilna aplikacija omogućava spajanje Turista i Lokalaca širom sveta. 
        Upoznaj skrivene male tajne svakog kutka na zemlji koristeći našu aplikaciju, izaberi vodiča i turu po svojoj meri 
        i uživaj u stvarima koje zna samo lokalno stanovnistvo. Ako si lokalac, imaš mogućnost da zaradiš dodadno, 
        pokazujući turistima neke stvari koje najbolje poznaješ u svom kraju, u svom gradu. Brzo, efikasno i jednostavno!`
      },
      {
        title: `Zup! App`,
        image: projectZUP,
        text: `Izrazi se lako i kreativno putem slike. Postavi sliku, podeli sa svetom ili samo sa svojim prijateljima. 
        Postavi izazov, odgovori na izazov. Odgovori slikom na sliku! `
      },
      {
        title: `Smart Nodes`,
        image: projectSmartNodes,
        text: `“IoT” rešenje koje nudi niz novih mogućnosti za praćenje i duboku analitiku kretanja prodajnih artikala. 
        Uparivanjem mikro i makro lokacije prodaje pojedinih artikala sa vremenom i društvenim dešavanjima, 
        u mogućnosti smo da izvedemo detaljnu analizu prodaje i zainteresovanosti posetilaca i kupaca retail prodajnih mreža.`
      },
      {
        title: `Awera`,
        image: projectAwera,
        text: `Projekat koji na kreativan i savremen način omogućava praćenje interesovanja posetilaca za proizvode i 
        usluge na određenim prodajnim mestima, na internacionalnom nivou.`
      }
    ]
  }
};

export const references = {
  eng: {
    title: "References",
    references: [
      {
        title: `International Consulting and Development`,
        svg: refICD,
        items: [
          `Development of information systems and`,
          `Business analysis of work processes`,
          `IoT making the concept`
        ]
      },
      {
        title: `Nites`,
        image: nitesImg,
        items: [
          `Development of an information software solution for the energy sector`
        ]
      },
      {
        title: `BG Invest`,
        image: bgInvestImg,
        items: [
          `Creating and implementing business solutions`,
          `Automation of business`,
          `Implementation of the information system for monitoring the realization of projects`
        ]
      },
      {
        title: `Incasso menagment`,
        image: incassoImg,
        items: [
          `Creation of tailor-made information system`,
          `Analysis and improvement of the work of Call Center`
        ]
      }
    ]
  },
  srb: {
    title: "Reference",
    references: [
      {
        title: `International Consulting and Development`,
        svg: refICD,
        items: [
          `Izrada informacionih sistema i`,
          `Poslovna analiza radnih procesa`,
          `IoT izrada koncepta`
        ]
      },
      {
        title: `Nites`,
        image: nitesImg,
        items: [
          `Izrada informacionog softverskog rešenja namenjenog energetskom sektoru`
        ]
      },
      {
        title: `BG Invest`,
        image: bgInvestImg,
        items: [
          `Izrada i uvođenje poslovnih resenja`,
          `Automatizacija poslovanja`,
          `Implementacija informacionog sistema za praćenje realizacije projekata`
        ]
      },
      {
        title: `Incasso menagment`,
        image: incassoImg,
        items: [
          `Izrada tailor-made informacionog sistema`,
          `Analiza i unapređenje rada Call centra`
        ]
      }
    ]
  }
};

export const footer = {
  eng: {
    address: `Majdanska Čukarica 16, Belgrade, Serbia`,
    phone: `+ 381 64 334 3297`,
    p1: `ICD Group`,
    p2: `Copyright © ICD Group 2022`
  },
  srb: {
    address: `Majdanska Čukarica 16, Beograd, Srbija`,
    phone: `+ 381 64 334 3297`,
    p1: `ICD Group`,
    p2: `Copyright © ICD Group 2022`
  }
};
